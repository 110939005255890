import { Link } from "react-router-dom";

export default function Breadcrumbs({ crumbs }) {
    return (
        <nav className="breadcrumbs">
            <ul>
                { crumbs.map(crumb => <li key={crumb.link}>
                    <Link to={ crumb.link }>{crumb.name}</Link>
                </li>) }
            </ul>
        </nav>
    );
}