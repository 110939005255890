import ChangableInput from "./ChangableInput";
import FallbackLink from "./FallbackLink";

export default function Pagination({
    page,
    pageCount,
    setPage,
    firstPage = null,
    pagesPerButton = null,
    buttonsAtStart = null,
    buttonsAtEnd = null,
    buttonsBeforeCurrent = null,
    buttonsAfterCurrent = null,
    minimumSkip = null,
    consistentWidth = true,
    pageLink = null,
}) {

    const atLeast = (n, m, d) => {
        if (typeof n === 'undefined' || n === null || isNaN(+n)) {
            return Math.floor(typeof d === 'undefined' || d === null || d < m ? m : d);
        }
        if (typeof m !== 'undefined' && n !== null && n < m) {
            return Math.floor(m);
        }
        return Math.floor(n);
    };

    const firstButton = atLeast(firstPage, null, 1);
    const lastButton = atLeast(firstButton + (Math.ceil((atLeast(pageCount, 1) - firstButton) / atLeast(pagesPerButton, 1))) * atLeast(pagesPerButton, 1), page, page);
    
    const buttonForPage = p => {
        // Which button belongs to this page (e.g. if buttons go 0, 2, 4 ... then page 3 is reached by button 2)
        if (typeof p == 'undefined' || p === null || isNaN(+p))
            return null;
        let n = firstButton + Math.ceil((p - firstButton) / atLeast(pagesPerButton, 1)) * atLeast(pagesPerButton, 1);
        if (n < firstButton) {
            n = firstButton;
        }
        if (n > lastButton) {
            n = lastButton;
        }
        return +n;
    }

    const showButton = n => {
        // Should button N be shown?
        
        if (n !== buttonForPage(n)) {
            // Page does not even have a button
            return false;
        }

        if (n <= currentButton + atLeast(pagesPerButton, 1) * atLeast(buttonsAfterCurrent, 0, 2)
            && n >= currentButton - atLeast(pagesPerButton, 1) * atLeast(buttonsBeforeCurrent, 0, 2)) {
            // Current page and surrounding buttons
            return true;
        }
        
        if (n < firstButton + atLeast(pagesPerButton, 1) * atLeast(buttonsAtStart, minButtonsAtStart, 1)) {
            // Button at start
            return true;
        }

        if (n > lastButton - atLeast(pagesPerButton, 1) * atLeast(buttonsAtEnd, minButtonsAtEnd, 1)) {
            // Button at end
            return true
        }

        return false;
    }

    const setPageManual = p => {
        if (isNaN(+p) || p < firstPage || p > lastButton + (atLeast(pagesPerButton, 1) - 1)) {
            return false;
        }
        setPage(buttonForPage(p));
        return true;
    };

    const currentButton = buttonForPage(atLeast(page, null, firstButton));

    const buttonList = [];
    let minButtonsAtStart = 0;
    let minButtonsAtEnd = 0;

    if (consistentWidth) {
        // Pad buttons at start and end if necessary
        if ((currentButton - firstButton) / atLeast(pagesPerButton, 1) < atLeast(buttonsAtStart, 0, 1) + atLeast(minimumSkip, 1, 2) + atLeast(buttonsBeforeCurrent, 0, 2)) {
            minButtonsAtStart = atLeast(buttonsAtStart, 0, 1) + atLeast(minimumSkip, 1, 2) + atLeast(buttonsBeforeCurrent, 0, 2) + atLeast(buttonsAfterCurrent, 0, 2);
        }
        if ((lastButton - currentButton) / atLeast(pagesPerButton, 1) < atLeast(buttonsAtEnd, 0, 1) + atLeast(minimumSkip, 1, 2) + atLeast(buttonsAfterCurrent, 0, 2)) {
            minButtonsAtEnd = atLeast(buttonsAtEnd, 0, 1) + atLeast(minimumSkip, 1, 2) + atLeast(buttonsBeforeCurrent, 0, 2) + atLeast(buttonsAfterCurrent, 0, 2);
        }
    }

    for (let p = firstButton; p <= lastButton; p += atLeast(pagesPerButton, 1)) {
        if (showButton(p)) {
            // Add this button
            buttonList.push(p);
        } else if (buttonList.length > 0 && buttonList[buttonList.length - 1] !== null) {
            // Add a spacer if there isn't any
            buttonList.push(null);
        }
    }
    if (buttonList.length > 0 && buttonList[buttonList.length - 1] === null) {
        // Don't end with a spacer
        buttonList.pop();
    }
    
    for (let n = buttonList.length - 2; n > 0; n--) {
        // See if there are any spacers that don't meet the minimum skip requirement
        // Go from high to low because the array size may grow
        if (buttonList[n] !== null) {
            continue;
        }
        let skippedCount = (buttonList[n + 1] - buttonList[n - 1]) / atLeast(pagesPerButton, 1) - 1;
        if (skippedCount < atLeast(minimumSkip, 1, 2)) {
            let spliceArgs = [n, 1];
            for (let s = 0; s < skippedCount; s++)
                spliceArgs.push(buttonList[n - 1] + atLeast(pagesPerButton, 1) * (s + 1));
            buttonList.splice.apply(buttonList, spliceArgs);
        }
    }

    return (
        <div className="page-buttons">
            <FallbackLink to={ typeof pageLink === 'function' && pageLink(currentButton - atLeast(pagesPerButton, 1)) }>
                <button
                    className={ "page-button white" + (currentButton <= firstButton ? " invisible" : "") }
                    onClick={ () => setPage(currentButton - atLeast(pagesPerButton, 1)) }
                    title="Previous page"
                >&lt;</button>
            </FallbackLink>
            { buttonList.map((p, n) => (
                p === null ? (
                    <ChangableInput className="page-button" key={ 's' + n } placeholder="..." value="" setValue={setPageManual} />
                ) : (
                    <FallbackLink to={ typeof pageLink === 'function' && pageLink(p) } key={ 'p' + p }>
                        <button
                            className={ "page-button" + (p === currentButton ? " submit" : " white") }
                            onClick={ () => setPage(p) }
                            title={ "Page " + p }
                        >{p}</button>
                    </FallbackLink>
                )
            )) }
            <FallbackLink to={ typeof pageLink === 'function' && pageLink(currentButton + atLeast(pagesPerButton, 1)) }>
                <button
                    className={ "page-button white" + (currentButton >= lastButton ? " invisible" : "") }
                    onClick={ () => setPage(currentButton + atLeast(pagesPerButton, 1)) }
                    title="Next page"
                >&gt;</button>
            </FallbackLink>
        </div>
    )
}