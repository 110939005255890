import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useOutletContext } from 'react-router-dom';
import DataLoader from '../components/DataLoader';
import Item from '../components/Item';
import PhotoGrid from '../components/PhotoGrid';
import ItemList from '../components/ItemList';

export default function Trips() {
    const { layout, setLayout } = useOutletContext();
    useEffect(() => { layout.title === null && setLayout.title("Photos by trip") }, [layout, setLayout]);

    const params = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState(null);
    // const [photoCount, setPhotoCount] = useState(0);

    const updateData = data => {
        setData(data);
        // setPhotoCount(+data?.photoCount || 0);
        setLayout.subtitle(data?.year || null);
        // setLayout.hasSearch(true);
        setLayout.breadcrumbs([
            data?.year && { link: "/trips", name: "All trips"},
            data?.id && data?.year && { link: "/trips/" + data.year, name: data.year },
        ].filter(v => !!v));
        if (data?.id) {
            setLayout.title(data.title);
            setLayout.thumb(data.thumb);
        } else {
            setLayout.title("Photos by trip");
        }
    };

    const onRedirect = target => {
        // DataLoader action was redirected
        if (target.match(/^new\/flickrmap\/trips/)) {
            navigate("/trips" + target.replace(/^new\/flickrmap\/trips/, '') , { replace: true });
            return true;
        } else {
            throw Error("Unexpected redirect: " + target);
        }
    };

    const tripToItem = (trip, year) => (
        <Item
            key={trip.handle}
            name={trip.title}
            link={ '/trips/' +  year + '/' + trip.handle }
            photoCount={trip.photoCount} 
            thumb={trip.thumb}
        />
    );

    const fullwidth = !data || !data.id;

    return (
        <DataLoader
            action={ 'new/flickrmap/trips' + (params['year'] ? '/' + params['year'] + (params['*'] ? '/' + params['*'] : '') : '') }
            setData={updateData}
            onRedirect={onRedirect}
            dependencies={[params]}
        >
            { data && <>
                { data.years && (
                    <ItemList
                        fullwidth={fullwidth}
                        multicol={true}
                    >
                        { data.years.map(year => (<li key={year}><ul className="no-list no-break mb10">
                            <li className="list-item" key={year}>
                                <Link to={ "/trips/" + year } className="button white"><h2>{ year }</h2></Link>
                            </li>
                            { data.trips[year]?.map(trip => tripToItem(trip, year)) }
                        </ul></li>)) }
                    </ItemList>
                )}

                { data.year && data.trips && (
                    <ItemList
                        fullwidth={fullwidth}
                        multicol={false}
                    >
                        { data.trips.map(trip => tripToItem(trip, data.year)) }
                    </ItemList>
                ) }

                { data.id && <PhotoGrid
                    perPage="30"
                    tripId={data.id}
                    // setPhotoCount={setPhotoCount}
                /> }
            </> }
        </DataLoader>
    );
}