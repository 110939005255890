import { useEffect, useState } from "react";
import Loading from "./Loading";

const baseUrl = 'https://rubenholthuijsen.nl/';

export async function dataFetcher({ action, params = null }) {
    let url = baseUrl + action;
    const query = [];
    if (typeof params === 'object' && params !== null) {
        for (const key in params) {
            if (params[key] !== null && typeof params[key] !== 'undefined') {
                query.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
            }
        }
    }
    if (query.length) {
        url += '?' + query.join('&');
    }
    return fetch(url, { headers: { Accept: 'application/json' }});
}

export default function DataLoader({ children, action, params, setData = null, onRedirect = null, dependencies = [] }) {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        let handled = false;
        setLoading(true);
        dataFetcher({action, params}).then(r => {
            if (r.redirected && typeof onRedirect === 'function') {
                const newUrl = r.url.indexOf(baseUrl) === 0 ? r.url.replace(baseUrl, '') : r.url;
                handled = !!onRedirect(newUrl);
            }
            return r.json();
        }).then(data => {
            if (handled) {
                return;
            }
            typeof setData === 'function' && setData(data);
            setLoading(false);
        });
        // ESlint does not understand this way of passing dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);

    return loading ? <Loading /> : children;
}