import { Link } from 'react-router-dom';
import CircleThumb from './CircleThumb';

export default function Item({ link, name, localName, photoCount, isExtra, thumb, redirect, description }) {
    return (<li className={ +photoCount === 0 ? "list-item light" : "list-item" }>
        <Link to={redirect?.link || link} className="button white">
            <div className="flex-nowrap">
                <CircleThumb src={thumb} />
                <div className={ [
                    'flex-grow',
                    'ml10',
                    isExtra ? 'extra-item' : '',
                ].join(' ') }>
                    <div className="bold">
                        <span className={ !redirect ? 'a' : '' }>{ name }</span>
                        { localName && <span className="unbold">{' '}({ localName.replace(/\r\n|\r|\n/g, ' / ') })</span> }
                        { redirect && <span className="unbold">, see&nbsp;</span> }
                        { redirect && <span className="a">{ redirect.name }</span> }
                    </div>
                    { description && <div><small className="light unbold">{description}</small></div> }
                    <div className="light">{ (+photoCount || 0) + (photoCount === 1 ? ' photo': ' photos') }</div>
                </div>
            </div>
        </Link>
    </li>);
}