import { useEffect, useState } from 'react';
import { Link, Outlet, useNavigation, useLocation } from 'react-router-dom';
import Loading from './components/Loading';
import PageTitle from './components/PageTitle';


export default function Root({ version = '' }) {

    const navigation = useNavigation();
    const location = useLocation();

    const layout = {};
    const setLayout = {};

    [layout.pathname, setLayout.pathname] = useState(null);
    [layout.title, setLayout.title] = useState(null);
    [layout.subtitle, setLayout.subtitle] = useState(null);
    [layout.breadcrumbs, setLayout.breadcrumbs] = useState(null);
    [layout.thumb, setLayout.thumb] = useState(null);
    [layout.search, setLayout.search] = useState(null);
    [layout.hasSearch, setLayout.hasSearch] = useState(false);
    [layout.keepSearch, setLayout.keepSearch] = useState(false);

    const initlayout = (newPathname) => {
        // Set all settings to defaults (when navigating to a new location)
        setLayout.pathname(newPathname);
        setLayout.title(null);
        setLayout.subtitle(null);
        setLayout.breadcrumbs(null);
        setLayout.thumb(null);
        setLayout.hasSearch(false);
        !layout.keepSearch && setLayout.search(null);
        setLayout.keepSearch(false);
    };

    // ESlint complains about missing "init" but adding it adds an extra call we don't want
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => (location && navigation.state === 'idle' && initlayout(location.pathname)), [location, navigation.state]);

    const capitalize = str => str.toString().substr(0, 1).toUpperCase() + str.toString().substr(1);

    return (
        <>
            <PageTitle layout={layout} setLayout={setLayout} />
            <main className={['flex', 'flex-grow', 'top-border', navigation.state === 'loading' ? 'loading' : ''].join(' ')}>
                { navigation.state === "loading" ? <Loading /> : <Outlet context={{ layout, setLayout }} /> }
            </main>
            <footer className="flex">
                { process.env.NODE_ENV === 'development' && ['', 'photos', 'locations', 'trips'].map(page => <div className="p5" key={page}><Link to={page}>{ capitalize(page || 'home') }</Link></div>) }
                <div className="flex-grow"></div>
                <div className="light p5 right">v{version}</div>
            </footer>
        </>
    );
}