export default function ItemList({ children, fullwidth = false, multicol = false, footerContent = null }) {
    return (
        <div className={ [
            'location-list',
            'pos-rel',
            fullwidth ? 'fullwidth' : '',
            multicol ? 'multicol' : '',
        ].join(' ') }>
            <nav className="flex-vert pos-abs-wide scroll-y-wide">
                <ul className="flex-grow no-list">
                    { children }
                </ul>
                <div className="p5">
                    { footerContent }
                </div>
            </nav>
        </div>
    );
}