import { useEffect, useState } from 'react';
import { useNavigate, useParams, useOutletContext } from 'react-router-dom';
import Checkbox from '../components/Checkbox';
import DataLoader from '../components/DataLoader';
import Item from '../components/Item';
import PhotoGrid from '../components/PhotoGrid';
import ItemList from '../components/ItemList';

export default function Locations() {
    const { layout, setLayout } = useOutletContext();
    useEffect(() => { layout.title === null && setLayout.title("Photo Locations") }, [layout, setLayout]);

    const params = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState(null);
    const [showHidden, setShowHidden] = useState(false);
    const [showUndisambiguated, setShowUndisambiguated] = useState(false);
    const [photoCount, setPhotoCount] = useState(0);

    const updateData = data => {
        setData(data);
        setPhotoCount(+data.location?.photoCount || 0);
        setLayout.hasSearch(true);
        if (data.location) {
            setLayout.title(data.location.name);
            setLayout.subtitle((data.location.localName && data.location.localName.replace(/\r\n/, ' / ')) || null);
            setLayout.breadcrumbs([{ link: "/locations", name: "All locations"}, ...(data.crumbs?.map(crumb => {
                crumb.link = "/locations/" + crumb.handle;
                return crumb;
            }) ?? [])]);
            setLayout.thumb(data.location.thumb);
        } else {
            setLayout.title("Photo Locations");
        }
    };

    const onRedirect = target => {
        // DataLoader action was redirected
        if (target.match(/^new\/flickrmap\/locations/)) {
            navigate("/locations" + target.replace(/^new\/flickrmap\/locations/, '') , { replace: true });
            return true;
        } else {
            throw Error("Unexpected redirect: " + target);
        }
    };

    const clearSearch = () => {
        setLayout.search(null);
    };

    const searchEverywhere = () => {
        setLayout.keepSearch(true);
        navigate("/locations");
    };

    const locationToItem = location => (
        <Item
            key={location.handle}
            name={location.name}
            localName={location.localName}
            description={location.parents} 
            link={ "/locations/" + location.handle }
            photoCount={location.photoCount} 
            thumb={location.thumb}
            isExtra={ location.type >= 2 }
            redirect={ location.redirect && { name: location.redirect.name, link: location.redirect.handle } }
        />
    );

    const fullwidth = !data || (!data.location && !showUndisambiguated && !layout.search);

    return (
        <DataLoader
            action={ 'new/flickrmap/locations/' + params['*'] }
            params={{ search: layout.search, all: showHidden ? 1 : null }}
            setData={updateData}
            onRedirect={onRedirect}
            dependencies={[params, showHidden, showUndisambiguated, layout.search]}
        >
            { data && <>
                <ItemList
                    fullwidth={fullwidth}
                    multicol={ data.subLocations?.length > 10 }
                    footerContent={ (<>
                        <Checkbox name="only" checked={showHidden} setChecked={setShowHidden}>Show hidden locations</Checkbox>
                        <br />
                        <Checkbox name="all" checked={showUndisambiguated} setChecked={setShowUndisambiguated}>Show undisambiguated</Checkbox>
                    </>) }
                >
                    { layout.search && <li className="extra-item m10">
                        { data.subLocations ? 'Results for ' : 'No results for ' }
                        <b>{ layout.search }</b>
                        { data.location && ' in ' + data.location.name }
                        { data.subLocations && ':' }
                    </li> }

                    { !layout.search && (data.location || showUndisambiguated) && <li className="extra-item m10">
                        <b>{ photoCount || 0 }</b>
                        { photoCount === 1 ? ' photo': ' photos' }
                    </li> }

                    { data.subLocations?.map(locationToItem) }
                    
                    { data.seeAlso?.length > 0 && <>
                        { +data.location?.type !== 3 && <li className="extra-item m10">See also:</li> }
                        { data.seeAlso.map(locationToItem) }
                    </> }

                    { data.search && <li className="extra-item m10">
                        <button onClick={clearSearch}>Clear search</button>
                        { data.location && <>{' '}<button onClick={searchEverywhere}>Search everywhere</button></> }
                    </li> }
                </ItemList>

                { !fullwidth && <PhotoGrid
                    perPage="30"
                    locationId={data.location?.id}
                    locationOnly={showUndisambiguated}
                    setPhotoCount={setPhotoCount}
                    search={layout.search}
                /> }
            </> }
        </DataLoader>
    );
}