export default function Checkbox({ children, checked = false, setChecked = null, name = null, value = 1, onChange = null }) {
    return (
        <label>
            <input type="checkbox" name={name} value={value} checked={checked} onChange={ event => {
                typeof setChecked === 'function' && setChecked(event.target.checked);
                typeof onChange === 'function' && onChange(event);
            } } />
            {' '} { children }
        </label>
    );
}