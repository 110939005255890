import { useEffect, useRef } from "react";

export default function ChangableInput({ value, setValue, placeholder = null, className = null, type = "text" }) {
    
    const inputRef = useRef(null);

    useEffect(() => {
        // React tries to force "onInput" and doesn't support actual onChange events, but we don't want that
        const input = inputRef.current;
        const updateValue = event => setValue(event.target.value);
        input && input.addEventListener('change', updateValue);
        return () => input && input.removeEventListener('change', updateValue);
        // ESlint complains about missing "setValue"
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Make sure the value is updated properly 
        const input = inputRef.current;
        input && input.value !== value && (input.value = value);
    }, [value])

    return (
        <input type={ type } className={ className || '' } defaultValue={ value || '' } placeholder={ placeholder || '' } ref={inputRef}></input>
    );
}