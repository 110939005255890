export default function SvgIcon({ icon, width = 16, height = 16 }) {
    const icons = {
        views: (<>
            <path
                style={{fill:'none',stroke:'currentColor',strokeWidth:0.3,strokeLinecap:'butt',strokeLinejoin:'miter',strokeDasharray:'none',strokeOpacity:1}}
                d="M 2.1166667,3.175 C 1.3098919,3.175 1.0575459,2.8997861 0.52916667,2.1166667 1.0575459,1.3335473 1.3098919,1.0666855 2.1166667,1.0583333 2.9234415,1.0499811 3.2053168,1.345359 3.7041667,2.1166667 3.2053169,2.8879744 2.9234415,3.175 2.1166667,3.175 Z"
            />
            <circle
                style={{fill:'none',stroke:'currentColor',strokeWidth:0.3,strokeDasharray:'none',strokeDashoffset:5,stopColor:'currentColor'}}
                cx="2.1130996"
                cy="2.1047475"
                r="0.31738254" />
        </>),
        faves: (<path
            style={{fill:'none',stroke:'currentColor',strokeWidth:0.3,strokeDasharray:'none',strokeDashoffset:5,stopColor:'currentColor'}}
            d="M 2.1024926,0.40013409 2.6062746,1.4209079 3.7327653,1.5845966 2.9176289,2.3791582 3.1100565,3.5010972 2.1024926,2.9713894 1.0949286,3.5010971 1.2873562,2.3791582 0.47221983,1.5845965 1.5987106,1.4209079 Z"
            transform="matrix(0.9461261,0,0,0.94517469,0.12737792,0.3251761)" />),
        comment: (<path
            style={{fill:'none',stroke:'currentColor',strokeWidth:0.3,strokeDasharray:'none',strokeDashoffset:5,stopColor:'currentColor'}}
            d="m 3.6248965,2.2414296 c 10e-8,0.6418706 -0.6768545,1.1622097 -1.5117969,1.1622097 L 1.143504,4.0020949 1.2529836,3.1973373 C 0.85919997,2.9875394 0.60130256,2.6376377 0.6013026,2.2414296 c 7e-8,-0.6418707 0.6768546,-1.16220965 1.511797,-1.16220962 0.8349424,0 1.5117969,0.52033892 1.5117969,1.16220962 z" />),
    };
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 4.2333332 4.2333335">
            { icons[icon] }
        </svg>
    );
}