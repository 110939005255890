import SvgIcon from "./SvgIcon";

export default function PhotoDescription({ photo }) {
    const icons = {};
    const handles = ['views', 'faves', 'comment'];
    for (const n in handles) {
        const handle = handles[n];
        if (typeof photo[handle] !== 'undefined') {
            icons[handle] = (+photo[handle]) || '–';
        }
    }
    return (
        <div className="edit-photo-desc">
            <div className="flex-item flex-100">
                <span className="edit-photo-desc-title a">{photo.title}</span>
                <br />
                <span className="edit-photo-desc-text" title={photo.description}>{photo.description}</span>
            </div>
            <div className="flex-item flex-grow">{' '}</div>
            { Object.keys(icons).map(icon => (
                <div className="flex-item light" key={icon}>
                    <div className="flex flex-align-center code">
                        <SvgIcon icon={icon} />
                        { icons[icon] }
                    </div>
                </div>
            )) }
        </div>
    );
}